.configure {
    width: 100%;
    height: calc(100vh - 170px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-left: 8px;
    padding-top: 0px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
    &_header {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
        font-weight: 700;
        font-size: 12px;
        // color: #a3a3a3;
        &_logo {
            img,
            svg {
                width: 120px;
                height: auto;
            }
        }
        label {
            word-break: break-word;
        }
    }
    &_trims,
    &_cameras
    {
        width: 100%;
        margin-bottom: 1.5em!important;
        &-notDropdown {
            pointer-events: none;
            svg {
                display: none;
            }
        }
    }
    &_colors {
        width: 85%;
        margin-bottom: 10px;
    }
    &_exteriorColors,
    &_interiorColors {
        &_title {
            p {
                padding: 0;
                font-weight: 400;
                line-height: 1;
            }
        }
        &_multiple {
            margin-bottom: 1.5em;
            .MuiGrid-item {
                padding: 12px 0px 0px;
            }
            .btnExteriorColor,
            .btnInteriorColor {
                width: 2.4em;
                height: 2.4em;
                display: block;
                background: #fff;
                border-radius: 50%;
                span {
                    background: transparent;
                    svg {
                        display: none;
                        margin-top: 4px;
                        font-size: 1.4em;
                    }
                }
                &.active {
                    span {
                        svg {
                            display: inline-block;
                        }
                    }
                }
            }
        }
        &_nameColor {
            margin-bottom: 15px;
            p {
                font-size: 0.7em;
                margin-top: 5px;
                // color: #5c5c5c;
            }
        }
    }
    &_flat {
        width: 85%;
        margin-bottom: 1.5em!important;
    }
    &_logout {
        width: 100%;
        margin-bottom: 0.5em;
        padding-left: 1em;
        // color: #d3d3d3;

        p {
            font-size: 0.7em;
            span {
                color: red;
                cursor: pointer;
            }
        }
    }
}

.lightTheme {
    background: #ebebeb !important;
    color: black !important;
}
// custom
.MuiMenu-list {
    .MuiListItem-root {
        font-size: 0.8em;
        padding-left: 1em;
    }
}
.MuiPopover-paper {
    border-radius: 0px;
}
