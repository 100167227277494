.add-comment {
    cursor: pointer;
    z-index: 1;
    .MuiPaper-rounded {
        border-radius: 0px;
        border: 4px solid #002856;
        margin: 11px 1rem;
    }
    .add-comment_tools {
        display: flex;
        padding: 0px;

        .MuiMenuItem-root {
            padding: 2px 5px;
            .arrow-tool {
                -ms-transform: rotate(320deg); /* IE 9 */
                transform: rotate(320deg);
                font-size: 24px;
                font-weight: 900;
            }
        }
    }
}
