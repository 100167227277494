.pano_wrapper {
  width: 100%;
  height: 100%;
  max-height: 720px;
  &_canvas {
    background-color: #fff;
    width: 100%;
    height: 100%;
  }
}
