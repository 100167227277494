.MuiFormControl-root {
    position: relative;
    .MuiSelect-icon {
        display: none;
    }
    .selectLabel {
        color: white;
        margin-bottom: 8px;
    }    

    .selectWrapper {
        position: relative;
        .MuiInput-root {
            width: 100%;
        }
        .iconDropDown {
            position: absolute;
            top: 5px;
            right: 0;
            pointer-events: none;
            top: 2px !important;
            right: 5px !important;
            svg {
                width: 0.8em;
            }
        }
        
        .MuiInputBase-root {
            border: 1px solid rgba(255, 255, 255, 0.5);
            // border: 1px solid rgba(0, 0, 0, 0.42);
            font-size: 0.8em;
            .MuiSelect-root {
                padding-left: 1em;
            }
            .MuiInputBase-input {
                background-color: black;
                color: white;
            }
        }
    }
}

.MuiFormControl-root.light {
    .selectLabel {
        color: black;
    }    

    .selectWrapper {
        .MuiInputBase-root {
            border: 1px solid rgba(0, 0, 0, 0.42);
            .MuiInputBase-input {
                background-color: white;
                color: black;
            }
        }
    }
}

.MuiPopover-root {
    .MuiMenu-paper {
        transform: translate(0px, 2px) !important;
    }
    .MuiList-root {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}
