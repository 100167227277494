.commonButton {
  background: transparent;
  border: 2px solid #5c5c5c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: #000;
  }
  img {
    width: 2em;
  }
}
.active {
}
