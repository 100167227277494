.login {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    height: 100vh !important;

    .logo {
        position: absolute;
        left: 30px;
        top: 30px;
        z-index: 10;
    }
    .alert_style {
        background-color: rgb(49, 49, 49);
        position: relative;
        margin: 10px;
        border-radius: 3px;
        text-align: center;
    }

    .alert_style .fade.alert.alert-primary.show {
        padding: 7px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
    }
    .login_form {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 320px;
        .login_input {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            input {
                width: 100%;
                margin-bottom: 20px;
                background: transparent;
                outline: none;
                color: #fff;
                font-size: 14px;
                padding: 5px;
                box-sizing: border-box;
                border: 1px solid #969696;
            }
        }
        .login_form_reset {
            display: flex;
            width: 100%;
            margin-top: 10px;
            justify-content: space-between;
            align-items: center;
            .btnFormSubmitLogin {
                cursor: pointer;
                background: transparent;
                border: none;
                .icon {
                    svg {
                        width: 23px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.reset-button {
    padding: .5em 1em;
    background: #000;
    border: 1px solid #fff;
    border-radius: .1em;
    cursor: pointer;
    & > .reset-password-text {
        color: #fff;
        flex: 1 1;
        font-size: 13px;
        cursor: pointer;
        align-items: center;
        display: flex;
    }
}

.login-button {
    padding: .5em 1em;
    background: #fff;
    border: 1px solid #fff;
    border-radius: .1em;
    cursor: pointer;
    & > p {
        color: #000
    }
    &:hover {
        background: #000;
        border: 1px solid #fff;
        & > p {
            color: #fff;
        }
        transition: .5s;
    }
}
