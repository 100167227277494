.pagination {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 20em;
    margin-top: 2em;
}

.pagination .button {
    cursor: pointer;
}

.pagination .hide {
    cursor: default !important;
    color: black;
}
