.projects {
    display: flex;
    justify-content: center;
    width: 100vw;
    min-height: 20vh;
    padding-left: 10%;
    max-width: 80%;
    min-width: 800px;
    @media (max-width: 1280px) {
        width: 100vw;
        padding: 1rem;
        margin: auto;
    }
    &_header {
        grid-area: header;
        border: grey 1px solid;
        padding: 10px 0;
    }
}