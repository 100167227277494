.main {
    width: 100%;
    height: 88vh;
    position: relative;
}
.loading-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.single-image {
    display: none;
    pointer-events: none;
    user-select: none;
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.single-image.show {
    display: block;
}
