.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    &_modal-body {
        position: absolute;
        background-color: white;
        border: 2px solid #000;
        padding: 2rem;
        .close-icon {
            float: right;
            margin-top: -1rem;
            margin-right: -1rem;
            cursor: pointer;
        }

        .body-content {
            padding: 1rem 2rem;
            min-height: 200px;
            max-width: 45rem;
        }
    }
}
